import React from 'react'
import styled from 'styled-components/macro'
import FooterNav from '../components/FooterNav'

export default function Datenschutz() {
  return (
    <>
      <PageStyled>
        <DatenschutzStyled>
          <h2>DATENSCHUTZERKLÄRUNG</h2>
          <h3>
            1) INFORMATION ÜBER DIE ERHEBUNG PERSONENBEZOGENER DATEN UND
            KONTAKTDATEN DES VERANTWORTLICHEN
          </h3>
          <p>
            1.1 Wir freuen uns, dass Sie unsere Website besuchen und bedanken
            uns für Ihr Interesse. Im Folgenden informieren wir Sie über den
            Umgang mit Ihren personenbezogenen Daten bei Nutzung unserer
            Website. Personenbezogene Daten sind hierbei alle Daten, mit denen
            Sie persönlich identifiziert werden können.
          </p>
          <p>
            1.2 Verantwortlicher für die Datenverarbeitung auf dieser Website im
            Sinne der Datenschutz-Grundverordnung (DSGVO) ist Rebel X UG
            (haftungsbeschränkt), Moldauweg 14, 21109 Hamburg, Deutschland,
            Tel.: 017621465577, E-Mail: info@gebuehrenrechner.com. Der für die
            Verarbeitung von personenbezogenen Daten Verantwortliche ist
            diejenige natürliche oder juristische Person, die allein oder
            gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung
            von personenbezogenen Daten entscheidet.
          </p>
          <p>
            1.3 Diese Website nutzt aus Sicherheitsgründen und zum Schutz der
            Übertragung personenbezogene Daten und anderer vertraulicher Inhalte
            (z.B. Bestellungen oder Anfragen an den Verantwortlichen) eine
            SSL-bzw. TLS-Verschlüsselung. Sie können eine verschlüsselte
            Verbindung an der Zeichenfolge „https://“ und dem Schloss-Symbol in
            Ihrer Browserzeile erkennen.
          </p>
          <h3>2) DATENERFASSUNG BEIM BESUCH UNSERER WEBSITE</h3>
          <p>
            Bei der bloß informatorischen Nutzung unserer Website, also wenn Sie
            sich nicht registrieren oder uns anderweitig Informationen
            übermitteln, erheben wir nur solche Daten, die Ihr Browser an
            unseren Server übermittelt (sog. „Server-Logfiles“). Wenn Sie unsere
            Website aufrufen, erheben wir die folgenden Daten, die für uns
            technisch erforderlich sind, um Ihnen die Website anzuzeigen:
          </p>
          <ul>
            <li>Unsere besuchte Website</li>
            <li>Datum und Uhrzeit zum Zeitpunkt des Zugriffes</li>
            <li>Menge der gesendeten Daten in Byte</li>
            <li>Quelle/Verweis, von welchem Sie auf die Seite gelangten</li>
            <li>Verwendeter Browser</li>
            <li>Verwendetes Betriebssystem</li>
            <li>Verwendete IP-Adresse (ggf.: in anonymisierter Form)</li>
          </ul>
          <p>
            Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis
            unseres berechtigten Interesses an der Verbesserung der Stabilität
            und Funktionalität unserer Website. Eine Weitergabe oder
            anderweitige Verwendung der Daten findet nicht statt. Wir behalten
            uns allerdings vor, die Server-Logfiles nachträglich zu überprüfen,
            sollten konkrete Anhaltspunkte auf eine rechtswidrige Nutzung
            hinweisen.
          </p>
          <h3>3) COOKIES</h3>
          <p>
            Um den Besuch unserer Website attraktiv zu gestalten und die Nutzung
            bestimmter Funktionen zu ermöglichen, verwenden wir auf
            verschiedenen Seiten sogenannte Cookies. Hierbei handelt es sich um
            kleine Textdateien, die auf Ihrem Endgerät abgelegt werden. Einige
            der von uns verwendeten Cookies werden nach dem Ende der
            Browser-Sitzung, also nach Schließen Ihres Browsers, wieder gelöscht
            (sog. Sitzungs-Cookies). Andere Cookies verbleiben auf Ihrem
            Endgerät und ermöglichen uns oder unseren Partnerunternehmen
            (Cookies von Drittanbietern), Ihren Browser beim nächsten Besuch
            wiederzuerkennen (persistente Cookies). Werden Cookies gesetzt,
            erheben und verarbeiten diese im individuellen Umfang bestimmte
            Nutzerinformationen wie Browser- und Standortdaten sowie
            IP-Adresswerte. Persistente Cookies werden automatisiert nach einer
            vorgegebenen Dauer gelöscht, die sich je nach Cookie unterscheiden
            kann.
          </p>
          <p>
            Sofern durch einzelne von uns implementierte Cookies auch
            personenbezogene Daten verarbeitet werden, erfolgt die Verarbeitung
            gemäß Art. 6 Abs. 1 lit. f DSGVO zur Wahrung unserer berechtigten
            Interessen an der bestmöglichen Funktionalität der Website sowie
            einer kundenfreundlichen und effektiven Ausgestaltung des
            Seitenbesuchs.
          </p>
          <p>
            Wir arbeiten unter Umständen mit Werbepartnern zusammen, die uns
            helfen, unser Internetangebot für Sie interessanter zu gestalten. Zu
            diesem Zweck werden für diesen Fall bei Ihrem Besuch unserer Website
            auch Cookies von Partnerunternehmen auf Ihrer Festplatte gespeichert
            (Cookies von Drittanbietern). Wenn wir mit vorbenannten
            Werbepartnern zusammenarbeiten, werden Sie über den Einsatz
            derartiger Cookies und den Umfang der jeweils erhobenen
            Informationen innerhalb der nachstehenden Absätze individuell und
            gesondert informiert.
          </p>
          <p>
            Bitte beachten Sie, dass Sie Ihren Browser so einstellen können,
            dass Sie über das Setzen von Cookies informiert werden und einzeln
            über deren Annahme entscheiden oder die Annahme von Cookies für
            bestimmte Fälle oder generell ausschließen können. Jeder Browser
            unterscheidet sich in der Art, wie er die Cookie-Einstellungen
            verwaltet. Diese ist in dem Hilfemenü jedes Browsers beschrieben,
            welches Ihnen erläutert, wie Sie Ihre Cookie-Einstellungen ändern
            können. Diese finden Sie für die jeweiligen Browser unter den
            folgenden Links:
          </p>
          <p>
            Internet Explorer:
            http://windows.microsoft.com/de-DE/windows-vista/Block-or-allow-cookies
            Firefox:
            https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen
            Chrome:
            http://support.google.com/chrome/bin/answer.py?hl=de&hlrm=en&answer=95647
            Safari: https://support.apple.com/kb/ph21411?locale=de_DE Opera:
            http://help.opera.com/Windows/10.20/de/cookies.html
          </p>
          <p>
            Bitte beachten Sie, dass bei Nichtannahme von Cookies die
            Funktionalität unserer Website eingeschränkt sein kann.
          </p>
          <h3>4) KONTAKTAUFNAHME</h3>
          <p>
            Im Rahmen der Kontaktaufnahme mit uns (z.B. per Kontaktformular oder
            E-Mail) werden personenbezogene Daten erhoben. Welche Daten im Falle
            eines Kontaktformulars erhoben werden, ist aus dem jeweiligen
            Kontaktformular ersichtlich. Diese Daten werden ausschließlich zum
            Zweck der Beantwortung Ihres Anliegens bzw. für die Kontaktaufnahme
            und die damit verbundene technische Administration gespeichert und
            verwendet. Rechtsgrundlage für die Verarbeitung der Daten ist unser
            berechtigtes Interesse an der Beantwortung Ihres Anliegens gemäß
            Art. 6 Abs. 1 lit. f DSGVO. Zielt Ihre Kontaktierung auf den
            Abschluss eines Vertrages ab, so ist zusätzliche Rechtsgrundlage für
            die Verarbeitung Art. 6 Abs. 1 lit. b DSGVO. Ihre Daten werden nach
            abschließender Bearbeitung Ihrer Anfrage gelöscht, dies ist der
            Fall, wenn sich aus den Umständen entnehmen lässt, dass der
            betroffene Sachverhalt abschließend geklärt ist und sofern keine
            gesetzlichen Aufbewahrungspflichten entgegenstehen.
          </p>
          <h3>5) NUTZUNG IHRER DATEN ZUR DIREKTWERBUNG</h3>
          <p>5.1 Anmeldung zu unserem E-Mail-Newsletter</p>
          <p>
            Wenn Sie sich zu unserem E-Mail Newsletter anmelden, übersenden wir
            Ihnen regelmäßig Informationen zu unseren Angeboten. Pflichtangabe
            für die Übersendung des Newsletters ist allein Ihre E-Mailadresse.
            Die Angabe weiterer evtl. Daten ist freiwillig und wird verwendet,
            um Sie persönlich ansprechen zu können. Für den Versand des
            Newsletters verwenden wir das sog. Double Opt-in Verfahren. Dies
            bedeutet, dass wir Ihnen erst dann einen E-Mail Newsletter
            übermitteln werden, wenn Sie uns ausdrücklich bestätigt haben, dass
            Sie in den Versand von Newsletter einwilligen. Wir schicken Ihnen
            dann eine Bestätigungs-E-Mail, mit der Sie gebeten werden durch
            Anklicken eines entsprechenden Links zu bestätigen, dass Sie künftig
            Newsletter erhalten wollen.
          </p>
          <p>
            Mit der Aktivierung des Bestätigungslinks erteilen Sie uns Ihre
            Einwilligung für die Nutzung Ihrer personenbezogenen Daten gemäß
            Art. 6 Abs. 1 lit. a DSGVO. Bei der Anmeldung zum Newsletter
            speichern wir Ihre vom Internet Service-Provider (ISP) eingetragene
            IP-Adresse sowie das Datum und die Uhrzeit der Anmeldung, um einen
            möglichen Missbrauch Ihrer E-Mail- Adresse zu einem späteren
            Zeitpunkt nachvollziehen zu können. Die von uns bei der Anmeldung
            zum Newsletter erhobenen Daten werden ausschließlich für Zwecke der
            werblichen Ansprache im Wege des Newsletters benutzt. Sie können den
            Newsletter jederzeit über den dafür vorgesehenen Link im Newsletter
            oder durch entsprechende Nachricht an den eingangs genannten
            Verantwortlichen abbestellen. Nach erfolgter Abmeldung wird Ihre
            E-Mailadresse unverzüglich in unserem Newsletter-Verteiler gelöscht,
            soweit Sie nicht ausdrücklich in eine weitere Nutzung Ihrer Daten
            eingewilligt haben oder wir uns eine darüberhinausgehende
            Datenverwendung vorbehalten, die gesetzlich erlaubt ist und über die
            wir Sie in dieser Erklärung informieren.
          </p>
          <p>5.2 Newsletterversand via CleverReach</p>
          <p>
            Der Versand unserer E-Mail-Newsletter erfolgt über den technischen
            Dienstleister CleverReach GmbH & Co. KG, Mühlenstr. 43, 26180
            Rastede („CleverReach“), an die wir Ihre bei der Newsletteranmeldung
            bereitgestellten Daten weitergeben. Diese Weitergabe erfolgt gemäß
            Art. 6 Abs. 1 lit. f DSGVO und dient unserem berechtigten Interesse
            an der Verwendung eines werbewirksamen, sicheren und
            nutzerfreundlichen Newslettersystems. Die von Ihnen zwecks
            Newsletterbezugs eingegebenen Daten (z.B. E-Mail-Adresse) werden auf
            den Servern von CleverReach in Deutschland bzw. Irland gespeichert.
          </p>
          <p>
            CleverReach verwendet diese Informationen zum Versand und zur
            statistischen Auswertung der Newsletter in unserem Auftrag. Für die
            Auswertung beinhalten die versendeten E-Mails sog. Web-Beacons bzw.
            Trackings-Pixel, die Ein-Pixel-Bilddateien darstellen, die auf
            unserer Website gespeichert sind. So kann festgestellt werden, ob
            eine Newsletter-Nachricht geöffnet und welche Links ggf. angeklickt
            wurden. Mit Hilfe des sog. Conversion-Trackings kann außerdem
            analysiert werden, ob nach Anklicken des Links im Newsletter eine
            vorab definierte Aktion erfolgt ist. Außerdem werden technische
            Informationen erfasst (z.B. Zeitpunkt des Abrufs, IP-Adresse,
            Browsertyp und Betriebssystem). Die Daten werden ausschließlich
            pseudonymisiert erhoben und werden nicht mir Ihren weiteren
            persönlichen Daten verknüpft, eine direkte Personenbeziehbarkeit
            wird ausgeschlossen. Diese Daten dienen ausschließlich der
            statistischen Analyse von Newsletterkampagnen. Die Ergebnisse dieser
            Analysen können genutzt werden, um künftige Newsletter besser an die
            Interessen der Empfänger anzupassen.
          </p>
          <p>
            Wenn Sie der Datenanalyse zu statistischen Auswertungszwecken
            widersprechen möchten, müssen Sie den Newsletterbezug abbestellen.
            Wir haben mit CleverReach einen Auftragsverarbeitungsvertrag
            abgeschlossen, in welchem wir die CleverReach verpflichten, die
            Daten unserer Kunden zu schützen und sie nicht an Dritte
            weiterzugeben. Weitere Informationen zur Datenanalyse von
            CleverReach können Sie hier nachlesen:
            https://www.cleverreach.com/de/funktionen/reporting-und-tracking/
            Die Datenschutzerklärung von CleverReach können Sie hier einsehen:
            https://www.cleverreach.com/de/datenschutz/.
          </p>
          <h3>6) ONLINE-MARKETING</h3>
          <p>Verwendung von Affiliate-Programmen</p>
          <p>
            – Amazon Partnerprogramm (AmazonPartnerNet) Wir nehmen am
            Partnerprogramm „AmazonPartnerNet“ der Amazon EU S.a.r.l., 5 Rue
            Plaetis, L-2338 Luxemburg (nachfolgend „Amazon“) teil. In diesem
            Zusammenhang haben wir auf unserer Website Werbeanzeigen als Links
            platziert, die zu Angeboten auf verschiedenen Amazon-Websites
            führen. Amazon setzt Cookies ein, hierbei handelt es sich um kleine
            Textdateien, die auf Ihrem Endgerät abgelegt werden, um die Herkunft
            von Bestellungen nachvollziehen zu können, die über solche Links
            generiert wurden. Dabei kann Amazon unter anderem erkennen, dass Sie
            den Partnerlink auf unserer Website angeklickt haben. Diese
            Informationen werden zur Zahlungsabwicklung zwischen uns und Amazon
            benötigt. Sofern die Informationen auch personenbezogen Daten
            enthalten, erfolgt die beschriebene Verarbeitung auf Grundlage
            unseres berechtigten finanziellen Interesses an der Abwicklung von
            Provisionszahlungen mit Amazon gemäß Art. 6 Abs. 1 lit. f DSGVO.
            Weitere Informationen zur Datennutzung durch Amazon finden Sie in
            der Amazon.de-Datenschutzerklärung unter
            http://www.amazon.de/gp/help/customer/display.html/ref=footer_privacy?ie=UTF8&nodeId=3312401
            Wenn Sie die Auswertung des Nutzerverhaltens via Cookies blockieren
            möchten, können Sie Ihren Browser so einstellen, dass Sie über das
            Setzen von Cookies informiert werden und einzeln über deren Annahme
            entscheiden oder die Annahme von Cookies für bestimmte Fälle oder
            generell ausschließen. Sie können die interessenbezogenen Anzeigen
            bei Amazon auch über den Link http://www.amazon.de/gp/dra/info
            deaktivieren.
          </p>
          <h3>7) WEBANALYSEDIENSTE</h3>
          <p>
            Google (Universal) Analytics – Google Analytics Diese Website
            benutzt Google Analytics, einen Webanalysedienst der Google LLC,
            1600 Amphitheatre Parkway, Mountain View, CA 94043, USA („Google“).
            Google Analytics verwendet sog. „Cookies“, Textdateien, die auf
            Ihrem Computer gespeichert werden und die eine Analyse der Benutzung
            der Website durch Sie ermöglichen. Die durch das Cookie erzeugten
            Informationen über Ihre Benutzung dieser Website (einschließlich der
            gekürzten IP-Adresse) werden in der Regel an einen Server von Google
            in den USA übertragen und dort gespeichert. Diese Website verwendet
            Google Analytics ausschließlich mit der Erweiterung
            „_anonymizeIp()“, die eine Anonymisierung der IP-Adresse durch
            Kürzung sicherstellt und eine direkte Personenbeziehbarkeit
            ausschließt. Durch die Erweiterung wird Ihre IP-Adresse von Google
            innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen
            Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum
            zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an
            einen Server von Google in den USA übertragen und dort gekürzt. In
            diesen Ausnahmefällen erfolgt diese Verarbeitung gemäß Art. 6 Abs. 1
            lit. f DSGVO auf Grundlage unseres berechtigten Interesses an der
            statistischen Analyse des Nutzerverhaltens zu Optimierungs- und
            Marketingzwecken. In unserem Auftrag wird Google diese Informationen
            benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über
            die Websiteaktivitäten zusammenzustellen und um weitere mit der
            Websitenutzung und der Internetnutzung verbundene Dienstleistungen
            uns gegenüber zu erbringen. Die im Rahmen von Google Analytics von
            Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten
            von Google zusammengeführt. Sie können die Speicherung der Cookies
            durch eine entsprechende Einstellung Ihrer Browser-Software
            verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem
            Fall gegebenenfalls nicht sämtliche Funktionen dieser Website
            vollumfänglich werden nutzen können. Sie können darüber hinaus die
            Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der
            Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die
            Verarbeitung dieser Daten durch Google verhindern, indem Sie das
            unter dem folgenden Link verfügbare Browser-Plugin herunterladen und
            installieren: http://tools.google.com/dlpage/gaoptout?hl=de
            Alternativ zum Browser-Plugin oder innerhalb von Browsern auf
            mobilen Geräten klicken Sie bitte auf den folgenden Link, um ein
            Opt-Out-Cookie zu setzen, der die Erfassung durch Google Analytics
            innerhalb dieser Website zukünftig verhindert (dieses Opt-Out-Cookie
            funktioniert nur in diesem Browser und nur für diese Domain, löschen
            Sie Ihre Cookies in diesem Browser, müssen Sie diesen Link erneut
            klicken): Google Analytics deaktivieren Google LLC mit Sitz in den
            USA ist für das us-europäische Datenschutzübereinkommen „Privacy
            Shield“ zertifiziert, welches die Einhaltung des in der EU geltenden
            Datenschutzniveaus gewährleistet. Mehr Informationen zum Umgang mit
            Nutzerdaten bei Google Analytics finden Sie in der
            Datenschutzerklärung von Google:
            https://support.google.com/analytics/answer/6004245?hl=de – Google
            Universal Analytics Diese Website benutzt Google Analytics, einen
            Webanalysedienst der Google LLC, 1600 Amphitheatre Parkway, Mountain
            View, CA 94043, USA („Google“). Google Analytics verwendet sog.
            „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden
            und die eine Analyse der Benutzung der Website durch Sie
            ermöglichen. Die durch das Cookie erzeugten Informationen über Ihre
            Benutzung dieser Website (einschließlich der gekürzten IP-Adresse)
            werden in der Regel an einen Server von Google in den USA übertragen
            und dort gespeichert. Diese Website verwendet Google Analytics
            ausschließlich mit der Erweiterung „_anonymizeIp()“, die eine
            Anonymisierung der IP-Adresse durch Kürzung sicherstellt und eine
            direkte Personenbeziehbarkeit ausschließt. Durch die Erweiterung
            wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der
            Europäischen Union oder in anderen Vertragsstaaten des Abkommens
            über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in
            Ausnahmefällen wird die volle IP-Adresse an einen Server von Google
            in den USA übertragen und dort gekürzt. In diesen Ausnahmefällen
            erfolgt diese Verarbeitung gemäß Art. 6 Abs. 1 lit. f DSGVO auf
            Grundlage unseres berechtigten Interesses an der statistischen
            Analyse des Nutzerverhaltens zu Optimierungs- und Marketingzwecken.
            In unserem Auftrag wird Google diese Informationen benutzen, um Ihre
            Nutzung der Website auszuwerten, um Reports über die
            Websiteaktivitäten zusammenzustellen und um weitere mit der
            Websitenutzung und der Internetnutzung verbundene Dienstleistungen
            uns gegenüber zu erbringen. Die im Rahmen von Google Analytics von
            Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten
            von Google zusammengeführt. Sie können die Speicherung der Cookies
            durch eine entsprechende Einstellung Ihrer Browser-Software
            verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem
            Fall gegebenenfalls nicht sämtliche Funktionen dieser Website
            vollumfänglich werden nutzen können. Sie können darüber hinaus die
            Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der
            Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die
            Verarbeitung dieser Daten durch Google verhindern, indem Sie das
            unter dem folgenden Link verfügbare Browser-Plugin herunterladen und
            installieren: http://tools.google.com/dlpage/gaoptout?hl=de
            Alternativ zum Browser-Plugin oder innerhalb von Browsern auf
            mobilen Geräten klicken Sie bitte auf den folgenden Link, um ein
            Opt-Out-Cookie zu setzen, der die Erfassung durch Google Analytics
            innerhalb dieser Website zukünftig verhindert (dieses Opt-Out-Cookie
            funktioniert nur in diesem Browser und nur für diese Domain, löschen
            Sie Ihre Cookies in diesem Browser, müssen Sie diesen Link erneut
            klicken): Google Analytics deaktivieren Google LLC mit Sitz in den
            USA ist für das us-europäische Datenschutzübereinkommen „Privacy
            Shield“ zertifiziert, welches die Einhaltung des in der EU geltenden
            Datenschutzniveaus gewährleistet. Diese Website verwendet Google
            Analytics zudem für eine geräteübergreifende Analyse von
            Besucherströmen, die über eine User-ID durchgeführt wird. Sie können
            in Ihrem Kundenkonto unter „Meine Daten“, „persönliche Daten“ die
            geräteübergreifende Analyse Ihrer Nutzung deaktivieren. Mehr
            Informationen zum Umgang mit Nutzerdaten bei Google Analytics finden
            Sie in der Datenschutzerklärung von Google:
            https://support.google.com/analytics/answer/6004245?hl=de
          </p>
          <h3>8) TOOLS UND SONSTIGES</h3>
          <p>8.1 Google reCAPTCHA</p>
          <p>
            Auf dieser Website verwenden wir auch die reCAPTCHA Funktion von
            Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA
            („Google“). Diese Funktion dient vor allem zur Unterscheidung, ob
            eine Eingabe durch eine natürliche Person erfolgt oder
            missbräuchlich durch maschinelle und automatisierte Verarbeitung
            erfolgt. Der Dienst inkludiert den Versand der IP-Adresse und ggf.
            weiterer von Google für den Dienst reCAPTCHA benötigter Daten an
            Google und erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis
            unseres berechtigten Interesses an der Feststellung der
            individuellen Willensgetragenheit von Handlungen im Internet und der
            Vermeidung von Missbrauch und Spam. Google LLC mit Sitz in den USA
            ist für das us-europäische Datenschutzübereinkommen „Privacy Shield“
            zertifiziert, welches die Einhaltung des in der EU geltenden
            Datenschutzniveaus gewährleistet. Weiterführende Informationen zu
            Google reCAPTCHA sowie die Datenschutzerklärung von Google können
            Sie einsehen unter: https://www.google.com/intl/de/policies/privacy/
          </p>
          <p>8.2 Google Web Fonts</p>
          <p>
            Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so
            genannte Web Fonts die von der Google LLC., 1600 Amphitheatre
            Parkway, Mountain View, CA 94043, USA („Google“) bereitgestellt
            werden. Beim Aufruf einer Seite lädt Ihr Browser die benötigten Web
            Fonts in ihren Browser-Cache, um Texte und Schriftarten korrekt
            anzuzeigen. Zu diesem Zweck muss der von Ihnen verwendete Browser
            Verbindung zu den Servern von Google aufnehmen. Hierdurch erlangt
            Google Kenntnis darüber, dass über Ihre IP-Adresse unsere Website
            aufgerufen wurde. Die Nutzung von Google Web Fonts erfolgt im
            Interesse einer einheitlichen und ansprechenden Darstellung unserer
            Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von
            Art. 6 Abs. 1 lit. f DSGVO dar. Wenn Ihr Browser Web Fonts nicht
            unterstützt, wird eine Standardschrift von Ihrem Computer genutzt.
            Google LLC mit Sitz in den USA ist für das us-europäische
            Datenschutzübereinkommen „Privacy Shield“ zertifiziert, welches die
            Einhaltung des in der EU geltenden Datenschutzniveaus gewährleistet.
            Weitere Informationen zu Google Web Fonts finden Sie unter
            https://developers.google.com/fonts/faq und in der
            Datenschutzerklärung von Google:
            https://www.google.com/policies/privacy/
          </p>
          <h3>9) RECHTE DES BETROFFENEN</h3>
          <p>
            9.1 Das geltende Datenschutzrecht gewährt Ihnen gegenüber dem
            Verantwortlichen hinsichtlich der Verarbeitung Ihrer
            personenbezogenen Daten umfassende Betroffenenrechte (Auskunfts- und
            Interventionsrechte), über die wir Sie nachstehend informieren:
          </p>
          <ul>
            <li>
              Auskunftsrecht gemäß Art. 15 DSGVO: Sie haben insbesondere ein
              Recht auf Auskunft über Ihre von uns verarbeiteten
              personenbezogenen Daten, die Verarbeitungszwecke, die Kategorien
              der verarbeiteten personenbezogenen Daten, die Empfänger oder
              Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt
              wurden oder werden, die geplante Speicherdauer bzw. die Kriterien
              für die Festlegung der Speicherdauer, das Bestehen eines Rechts
              auf Berichtigung, Löschung, Einschränkung der Verarbeitung,
              Widerspruch gegen die Verarbeitung, Beschwerde bei einer
              Aufsichtsbehörde, die Herkunft Ihrer Daten, wenn diese nicht durch
              uns bei Ihnen erhoben wurden, das Bestehen einer automatisierten
              Entscheidungsfindung einschließlich Profiling und ggf.
              aussagekräftige Informationen über die involvierte Logik und die
              Sie betreffende Tragweite und die angestrebten Auswirkungen einer
              solchen Verarbeitung, sowie Ihr Recht auf Unterrichtung, welche
              Garantien gemäß Art. 46 DSGVO bei Weiterleitung Ihrer Daten in
              Drittländer bestehen;
            </li>
            <li>
              Recht auf Berichtigung gemäß Art. 16 DSGVO: Sie haben ein Recht
              auf unverzügliche Berichtigung Sie betreffender unrichtiger Daten
              und/oder Vervollständigung Ihrer bei uns gespeicherten
              unvollständigen Daten;
            </li>
            <li>
              Recht auf Löschung gemäß Art. 17 DSGVO: Sie haben das Recht, die
              Löschung Ihrer personenbezogenen Daten bei Vorliegen der
              Voraussetzungen des Art. 17 Abs. 1 DSGVO zu verlangen. Dieses
              Recht besteht jedoch insbesondere dann nicht, wenn die
              Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung
              und Information, zur Erfüllung einer rechtlichen Verpflichtung,
              aus Gründen des öffentlichen Interesses oder zur Geltendmachung,
              Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;
            </li>
            <li>
              Recht auf Einschränkung der Verarbeitung gemäß Art. 18 DSGVO: Sie
              haben das Recht, die Einschränkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen, solange die von Ihnen
              bestrittene Richtigkeit Ihrer Daten überprüft wird, wenn Sie eine
              Löschung Ihrer Daten wegen unzulässiger Datenverarbeitung ablehnen
              und stattdessen die Einschränkung der Verarbeitung Ihrer Daten
              verlangen, wenn Sie Ihre Daten zur Geltendmachung, Ausübung oder
              Verteidigung von Rechtsansprüchen benötigen, nachdem wir diese
              Daten nach Zweckerreichung nicht mehr benötigen oder wenn Sie
              Widerspruch aus Gründen Ihrer besonderen Situation eingelegt
              haben, solange noch nicht feststeht, ob unsere berechtigten Gründe
              überwiegen;
            </li>
            <li>
              Recht auf Unterrichtung gemäß Art. 19 DSGVO: Haben Sie das Recht
              auf Berichtigung, Löschung oder Einschränkung der Verarbeitung
              gegenüber dem Verantwortlichen geltend gemacht, ist dieser
              verpflichtet, allen Empfängern, denen die Sie betreffenden
              personenbezogenen Daten offengelegt wurden, diese Berichtigung
              oder Löschung der Daten oder Einschränkung der Verarbeitung
              mitzuteilen, es sei denn, dies erweist sich als unmöglich oder ist
              mit einem unverhältnismäßigen Aufwand verbunden. Ihnen steht das
              Recht zu, über diese Empfänger unterrichtet zu werden.
            </li>
            <li>
              Recht auf Datenübertragbarkeit gemäß Art. 20 DSGVO: Sie haben das
              Recht, Ihre personenbezogenen Daten, die Sie uns bereitgestellt
              haben, in einem strukturierten, gängigen und maschinenlesebaren
              Format zu erhalten oder die Übermittlung an einen anderen
              Verantwortlichen zu verlangen, soweit dies technisch machbar ist;
            </li>
            <li>
              Recht auf Widerruf erteilter Einwilligungen gemäß Art. 7 Abs. 3
              DSGVO: Sie haben das Recht, eine einmal erteilte Einwilligung in
              die Verarbeitung von Daten jederzeit mit Wirkung für die Zukunft
              zu widerrufen. Im Falle des Widerrufs werden wir die betroffenen
              Daten unverzüglich löschen, sofern eine weitere Verarbeitung nicht
              auf eine Rechtsgrundlage zur einwilligungslosen Verarbeitung
              gestützt werden kann. Durch den Widerruf der Einwilligung wird die
              Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf
              erfolgten Verarbeitung nicht berührt;
            </li>
            <li>
              Recht auf Beschwerde gemäß Art. 77 DSGVO: Wenn Sie der Ansicht
              sind, dass die Verarbeitung der Sie betreffenden personenbezogenen
              Daten gegen die DSGVO verstößt, haben Sie – unbeschadet eines
              anderweitigen verwaltungsrechtlichen oder gerichtlichen
              Rechtsbehelfs – das Recht auf Beschwerde bei einer
              Aufsichtsbehörde, insbesondere in dem Mitgliedstaat Ihres
              Aufenthaltsortes, Ihres Arbeitsplatzes oder des Ortes des
              mutmaßlichen Verstoßes.
            </li>
          </ul>
          <p>9.2 WIDERSPRUCHSRECHT</p>
          <p>
            WENN WIR IM RAHMEN EINER INTERESSENABWÄGUNG IHRE PERSONENBEZOGENEN
            DATEN AUFGRUND UNSERES ÜBERWIEGENDEN BERECHTIGTEN INTERESSES
            VERARBEITEN, HABEN SIE DAS JEDERZEITIGE RECHT, AUS GRÜNDEN, DIE SICH
            AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIESE VERARBEITUNG
            WIDERSPRUCH MIT WIRKUNG FÜR DIE ZUKUNFT EINZULEGEN. MACHEN SIE VON
            IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE VERARBEITUNG DER
            BETROFFENEN DATEN. EINE WEITERVERARBEITUNG BLEIBT ABER VORBEHALTEN,
            WENN WIR ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG
            NACHWEISEN KÖNNEN, DIE IHRE INTERESSEN, GRUNDRECHTE UND
            GRUNDFREIHEITEN ÜBERWIEGEN, ODER WENN DIE VERARBEITUNG DER
            GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN
            DIENT. WERDEN IHRE PERSONENBEZOGENEN DATEN VON UNS VERARBEITET, UM
            DIREKTWERBUNG ZU BETREIBEN, HABEN SIE DAS RECHT, JEDERZEIT
            WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER
            PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN.
            SIE KÖNNEN DEN WIDERSPRUCH WIE OBEN BESCHRIEBEN AUSÜBEN. MACHEN SIE
            VON IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE VERARBEITUNG
            DER BETROFFENEN DATEN ZU DIREKTWERBEZWECKEN.
          </p>
          <h3>10) DAUER DER SPEICHERUNG PERSONENBEZOGENER DATEN</h3>
          <p>
            Die Dauer der Speicherung von personenbezogenen Daten bemisst sich
            anhand der jeweiligen gesetzlichen Aufbewahrungsfrist (z.B. handels-
            und steuerrechtliche Aufbewahrungsfristen). Nach Ablauf der Frist
            werden die entsprechenden Daten routinemäßig gelöscht, sofern sie
            nicht mehr zur Vertragserfüllung oder Vertragsanbahnung erforderlich
            sind und/oder unsererseits kein berechtigtes Interesse an der
            Weiterspeicherung fortbesteht.
          </p>
        </DatenschutzStyled>
      </PageStyled>
      <FooterNav />
    </>
  )
}

const PageStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`

const DatenschutzStyled = styled.div`
  width: 100%;
  background: white;
  padding: 10px 10px;
  border-radius: 4px;
  border: 1px solid #e8e8e8;
  margin: 50px 0;
  box-sizing: border-box;

  h2 {
    padding: 0 20px;
    font-size: 20px;
  }

  p {
    padding: 0 20px;
    word-wrap: break-word;
  }
`
