import React from 'react'
// import styled from 'styled-components/macro'

export default function Blog() {
  return (
    <div>
      <h1>Blog</h1>
    </div>
  )
}
