import React from 'react'
import styled from 'styled-components/macro'

export default function Header() {
  const logo = require('../assets/images/logo.png')
  return (
    <HeaderStyled>
      <a href="/">
        <img className="logo" src={logo} alt="paypal gebuehrenrechner logo" />
      </a>
    </HeaderStyled>
  )
}

const HeaderStyled = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: 600px) {
    flex-direction: row;
    justify-content: space-between;
  }

  .logo {
    width: 240px;
    margin: 20px auto;

    @media only screen and (min-width: 600px) {
      margin-left: 100px;
      width: 300px;
    }
  }
`
