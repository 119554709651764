import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import './App.css'
import Header from './components/Header'
import PayPalCalculator from './pages/PayPalCalculator'
import Blog from './pages/Blog'
import Impressum from './pages/Impressum'
import Datenschutz from './pages/Datenschutz'
// import './assets/fonts/Nunito/static/Nunito-BoldItalic.ttf'

export default function App() {
  return (
    <Router>
      <Header />
      <Switch>
        <Route path="/blog">
          <Blog />
        </Route>
        <Route path="/impressum">
          <Impressum />
        </Route>
        <Route path="/datenschutz">
          <Datenschutz />
        </Route>
        <Route path="/">
          <PayPalCalculator />
        </Route>
      </Switch>
    </Router>
  )
}
