import React from 'react'
import styled from 'styled-components/macro'

export default function Faq() {
  return (
    <FaqStyled>
      <h2 id="faq">Häufige Fragen & Antworten</h2>
      <Question>Mit PayPal Geld an Freunde senden wirklich kostenlos?</Question>
      <Answer>
        Ja. Wenn Sie Geld an Freunde oder Familie senden ist das mit AnswerayPal
        innerhalb Deutschlands oder der EU kostenlos. Das heißt es werden keine
        PayPal Gebühren bei der Transaktion berechnet. Das gilt für alle
        möglichen PayPal Zahlungsarten, wie PayPal-Guthaben, Bankkonto oder
        Kreditkarte. Gebühren fallen an wenn Geld in einer anderen Währung
        gesendet wird.
      </Answer>
      <Question>Wie hoch sind die PayPal-Gebühren als Verkäufer?</Question>
      <Answer>
        PayPal berechnet 2,49% + 0,35€ für jede Transaktion. Händler, die
        gewisse monatliche Transaktionsvolumen überschreiten, erhalten
        günstigere variable Gebühren. Dazu erfahren Sie mehr in der{' '}
        <a href="https://www.paypal.com/de/webapps/mpp/paypal-fees">
          Gebührenübersicht von PayPal
        </a>
        .
      </Answer>
      <Question>
        Wie hoch sind die PayPal-Gebühren beim Verkauf über eBay?
      </Question>
      <Answer>
        Bei dem Verkauf über eBay fallen neben den eBay Verkäufsgebühren von 10%
        auch PayPal-Gebühren i.H.v. 2,49% vom Gesamterlös (inkl. Versandkosten)
        + 0,35€ an. Sie können also grob rechnen, dass insgesamt rund 13%
        Gebühren anfallen. Im Gegensatz zu eBay zieht PayPal die Gebühren direkt
        bei der Transaktion ab. eBay hingegen rechnet am Monatsende ab und zieht
        die Gebühren z.B. per Lastschriftverfahren ein.
      </Answer>
      <Question>Sind Paypal Einkäufe abgesichert?</Question>
      <Answer>
        Ja! PayPal bietet für Zahlungen von Einkäufen den PayPal Käuferschutz
        an. Dieser sorgt dafür, dass der Käufer sein Geld zurück erstattet
        bekommt, wenn die Bestellung nicht ankommt oder der Artikel nicht mit
        der Angebotsbeschreibung übereinstimmt. Achtung! Der PayPal-Käuferschutz
        gilt allerdings nur bei „Geld senden für Waren und Dienstleistungen“ und
        nicht bei der gebührenfreien Variante „Geld an Freunde und Familie
        senden“.
      </Answer>
      <Question>Sind Paypal Zahlungen sicher?</Question>
      <Answer>
        PayPal setzt bei jeder Transaktion moderne Verschlüsselungstechnologie
        ein, sodass es wahrscheinlich nicht weniger risikobehaftet ist, als eine
        übliche Banküberweisung. Zusätzlich deckt der PayPal-Käuferschutz Sie
        ab, falls die Bestellung nicht ankommt oder von Angebotsbeschreibung
        abweicht.
      </Answer>
    </FaqStyled>
  )
}

const FaqStyled = styled.div`
  padding: 20px;
  color: #292929;
  @media only screen and (min-width: 600px) {
    padding: 20px 80px;
  }

  h2 {
    font-size: 30px;
    text-align: center;
    margin: 20px 0 40px;
    line-height: 1.2;
  }
`
const Question = styled.h3`
  font-size: 22px;
  font-weight: bold;
  text-align: left;
  margin: 20px 0 10px;
`
const Answer = styled.p`
  text-align: left;
  font-size: 16px;
  margin: 0 0 50px;
`
