import React from 'react'
import styled from 'styled-components/macro'
import { NavLink } from 'react-router-dom'

export default function FooterNav() {
  return (
    <FooterNavStyled>
      <ul>
        <li>
          <NavLink to="/impressum">Impressum</NavLink>
        </li>
        <li>
          <NavLink to="/datenschutz">Datenschutz</NavLink>
        </li>
      </ul>
    </FooterNavStyled>
  )
}

const FooterNavStyled = styled.footer`
  color: white;
  margin: 50px 0;

  ul {
    list-style-type: none;
    display: flex;
    padding-left: 0;
  }

  li {
    padding: 0 10px;
  }

  li a {
    text-decoration: none;
    color: white;
  }

  li a:hover {
    cursor: pointer;
    color: #28edbf;
  }
`
