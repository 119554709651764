import React from 'react'
import styled from 'styled-components/macro'

export default function About() {
  return (
    <AboutStyled>
      <h2 id="about">Über den PayPal Gebührenrechner</h2>
      <p>
        Falls Sie schon mal etwas über eBay verkauft haben, haben Sie bestimmt
        die Zahlung des Käufers über PayPal erhalten. Dabei ist Ihnen bestimmt
        aufgefallen, dass auf Ihrem PayPal Konto weniger Geld angekommen ist,
        als Sie beim Verkauf generiert haben.
      </p>
      <p>
        Das liegt daran, dass PayPal für geschäftliche Zahlungen Gebühren
        erhebt. PayPal berechnet 2,49% + 0,35€ für jede Transaktion. Händler,
        die gewisse monatliche Transaktionsvolumen überschreiten, erhalten
        günstigere variable Gebühren. Dazu erfahren Sie mehr in der{' '}
        <a href="https://www.paypal.com/de/webapps/mpp/paypal-fees">
          Gebührenübersicht von PayPal
        </a>
        .
      </p>
      <p>
        Mit dem PayPal Gebührenrechner möchten wir Ihnen die Berechnung der
        PayPal Gebühren zukünftig erleichtern. Sie geben den Betrag ein, den Sie
        versenden oder erhalten möchten und der Online Gebührenrechner berechnet
        die daraus resultierende PayPal Gebühr, sowie den zu erwarteten
        Nettobetrag.
      </p>
      <p>
        Da es sich hierbei um ein Hobbyprojekt handelt, dass aus Eigennutzung
        entstanden ist, übernehmen wir kein Gewähr für die Richtigkeit der
        ermittelten Werte. Falls Sie einen Fehler entdecken, schreiben Sie uns
        gerne eine Mail (am besten mit Screenshot) an info@gebuehrenrechner.com.
      </p>
    </AboutStyled>
  )
}

const AboutStyled = styled.div`
  padding: 20px;
  color: #292929;
  @media only screen and (min-width: 600px) {
    padding: 20px 80px;
  }

  h2 {
    font-size: 30px;
    text-align: center;
    margin: 20px 0 40px;
    line-height: 1.2;
  }

  p {
    text-align: left;
    font-size: 16px;
    margin: 10px 0 30px;
  }
`
