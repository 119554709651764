import React, { useState } from 'react'
import styled from 'styled-components/macro'
import About from '../components/About'
import Faq from '../components/Faq'
import FooterNav from '../components/FooterNav'

export default function PayPalCalculator() {
  const [amount, setAmount] = useState(25)
  const handleFocus = (event) => event.target.select()

  return (
    <StyledCalculator>
      <MainPage>
        <Headline>
          <h1>Paypal Gebühren schnell und einfach berechnen</h1>
        </Headline>
        <Calculator>
          <Title>Betrag</Title>
          <Amount>
            <input
              type="number"
              inputmode="decimal"
              step="0.01"
              pattern="[0-9]*"
              value={amount}
              onFocus={handleFocus}
              onChange={(e) => setAmount(e.target.value)}
            />
          </Amount>

          <Title>PayPal Gebühr</Title>
          <Result className="fee">
            <span>
              -{(amount * 0.0249 + 0.35).toFixed(2).replace('.', ',')}{' '}
            </span>
          </Result>

          <Title>Restbetrag </Title>
          <Result className="outcome">
            <span>
              {(amount - (amount * 0.0249 + 0.35)).toFixed(2).replace('.', ',')}{' '}
            </span>
          </Result>
          <Notice>
            Die PayPal-Gebühr setzt sich zusammen aus 0,35€ Grundgebühr zzgl.
            2,49% vom Transaktionsvolumen.
          </Notice>
        </Calculator>
      </MainPage>
      <TextArea>
        <Faq />
        <About />
      </TextArea>
      <FooterNav />
    </StyledCalculator>
  )
}

const StyledCalculator = styled.div`
  display: grid;
  justify-items: center;
`

const Calculator = styled.div`
  width: 300px;
  margin: 20px;
  display: grid;
  align-items: center;
  justify-self: center;

  @media only screen and (min-width: 600px) {
    justify-items: left;
    margin: 120px 0;
  }
`

const Amount = styled.div`
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: auto 1fr;
  max-width: 300px;
  align-items: center;
  box-shadow: 1px 1px 4px #2c4985;
  border: 0;
  border-radius: 2px;
  background: #6699ff;
  position: relative;
  color: white;
  font-size: 48px;
  font-weight: 600;

  &:before {
    position: absolute;
    top: calc(50% - 24px);
    content: '€';
    right: 15px;
    line-height: 48px;
  }

  input {
    text-align: center;
    background: #6699ff;
    border: 0;
    color: white;
    font-family: 'Nunito';
    -webkit-appearance: none;
    margin: 0;
    height: 90px;
    font-size: 48px;
    font-weight: 600;
    box-sizing: border-box;
    width: 100%;
    padding-right: 50px;

    @media only screen and (min-width: 600px) {
      height: 98px;
      width: 298px;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Works for Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`

const Title = styled.p`
  color: white;
  margin: 0 0 8px 0;
  font-size: 18px;
  font-weight: 600;

  @media only screen and (min-width: 600px) {
    font-size: 24px;
  }
`

const Result = styled.div`
  text-align: center;
  font-size: 48px;
  font-weight: 600;
  height: 90px;
  width: 300px;
  margin-top: 0;
  margin-bottom: 20px;
  align-content: center;
  display: grid;
  background: #6699ff;
  border: none;
  border-radius: 2px;
  box-shadow: 1px 1px 4px #2c4985;
  position: relative;

  &:before {
    position: absolute;
    top: calc(50% - 24px);
    content: '€';
    right: 15px;
    line-height: 48px;
  }

  span {
    padding-right: 50px;
  }

  @media only screen and (min-width: 600px) {
    height: 100px;
  }

  &.outcome {
    color: #bcffb8;
  }

  &.fee {
    color: #ffb0b0;
  }
`

const TextArea = styled.div`
  width: 90%;
  background: white;
  border-radius: 4px;
  margin: 50px 0;
  border: 1px solid #e8e8e8;

  @media only screen and (min-width: 600px) {
    width: 70%;
    padding: 30px 50px;
    margin-bottom: 200px;
  }
`

const Headline = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  padding: 20px;

  h1 {
    text-align: center;
    color: white;
    font-size: 34px;
    font-weight: 700;
    line-height: 1.2;

    @media only screen and (min-width: 600px) {
      font-size: 60px;
      padding: 0 40px;
      text-align: left;
      line-height: 1.3;
      max-width: 400px;
    }
  }
`
const MainPage = styled.div`
  display: grid;

  @media only screen and (min-width: 600px) {
    grid-template-columns: 1fr 1fr;
    height: 800px;
    /* height: 100vh; */
  }
`

const Notice = styled.p`
  font-size: 11px;
  color: rgba(247, 247, 247, 60%);
  margin-top: 0;
  text-align: center;
`
