import React from 'react'
import styled from 'styled-components/macro'
import FooterNav from '../components/FooterNav'

export default function Impressum() {
  return (
    <>
      <PageStyled>
        <ImpressumStyled>
          <h2>Impressum</h2>
          <p>
            Rebel X UG (haftungsbeschränkt) <br />
            Moldauweg 14 <br />
            21109 Hamburg <br />
            Deutschland
          </p>
          <p>
            Tel.: 017621465577 <br />
            E-Mail: info@gebuehrenrechner.com
          </p>
          <p>
            Registergericht: Amtsgericht Hamburg <br />
            Registernummer: HRB 133283 <br />
            Geschäftsführer: Timo Dilg <br />
            Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:
            DE296835818
          </p>
          <p>
            Plattform der EU-Kommission zur Online-Streitbeilegung:
            https://ec.europa.eu/consumers/odr <br />
            Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor einer
            Verbraucherschlichtungsstelle nicht verpflichtet, hierzu jedoch
            grundsätzlich bereit. <br />
            Verantwortliche/r i.S.d. § 55 Abs. 2 RStV: Timo Dilg, Moldauweg 14,
            21109 Hamburg
          </p>
        </ImpressumStyled>
      </PageStyled>
      <FooterNav />
    </>
  )
}

const PageStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  min-height: calc(100vh - 200px);

  @media only screen and (min-width: 600px) {
    padding: 0 100px;
  }
`

const ImpressumStyled = styled.div`
  background: white;
  padding: 30px 10px;
  border-radius: 4px;
  border: 1px solid #e8e8e8;
  margin: 50px 0 0;
  width: 100%;
  box-sizing: border-box;

  h2 {
    padding: 0 20px;
  }

  p {
    padding: 0 20px;
  }
`
